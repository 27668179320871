module services {
    export module logistics {
        export class LiveTrackingService implements interfaces.logistics.ILiveTrackingService {

            static $inject = ["$resource", "ENV","$http", "$timeout","generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,private $http: ng.IHttpService,private $timeout: ng.ITimeoutService,public generalService: interfaces.applicationcore.IGeneralService) {
            }


    
            getLiveTrackingData(consignmentId?: number,manifestId?:number): ng.resource.IResourceClass<interfaces.logistics.ILiveTrackingViewModel>
            {
                return this.$resource<interfaces.logistics.ILiveTrackingViewModel>(this.ENV.DSP_URL + "LiveTracking/GetLiveTrackingData",
                    {
                        consignmentId: consignmentId?consignmentId:0,
                        manifestId: manifestId?manifestId:0
                    }, {
                        get: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }

            getFourKitesCSVData(consignmentId: number): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'LiveTracking/GetFourKitesCSVData?consignmentId='+consignmentId;



                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'FourKites.csv';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }    



        }
    }
    angular.module("app").service("LiveTrackingService", services.logistics.LiveTrackingService);
}